import React, { Component } from 'react';
import './App.css';
import GuestLogin from './components/GuestLogin';
import * as featureTypeData from './utils/featureTypeData';
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			featureType: [],
			isAppUpdated: false
		};
	}

	componentDidMount() {
		let appUpdateQuery = this.getQuery()["isAppUpdated"]
		this.setState({
			featureType: featureTypeData.returnFeatureData(decodeURI(this.getQuery()["featureType"]).replace(/^"(.*)"$/, '$1')),
			isAppUpdated: appUpdateQuery ? true : false
		});
	  }

	  getQuery = () => {
		let b = window.location.search
		  .slice(1)
		  .split("&")
		  .map((qStr) => qStr.split("="))
		  .reduce((acc, inc) => {
			acc[inc[0]] = inc[1];
			return acc;
		  }, {});
	
		return b;
	  };

	render() {
		return (
			<div className="App">
				<GuestLogin isAppUpdated={this.state.isAppUpdated} featureType={this.state.featureType} />
			</div>
		);
	}
}

export default App;
