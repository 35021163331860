import classInteractionImage from "../images/ClassInteractionImage.svg";
import liveClassImg from "../images/LiveClassImg.svg";
import recordingsImg from "../images/RecordingsImg.svg";

import assignment1 from "../images/assignment1.png";
import assignment2 from "../images/assignment2.png";
import assignment3 from "../images/assignment3.png";

import test1 from "../images/test1.png";
import test2 from "../images/test2.png";
import test3 from "../images/test3.png";

import courses1 from "../images/courses1.png";
import courses2 from "../images/courses2.png";

import practicalfile1 from "../images/practicalfile1.png";
import practicalfile2 from "../images/practicalfile2.png";

import candm1 from "../images/candm1.png";

const featureTypeEnum = {
    LiveClasses: "LiveClasses",
    Assignments: "Assignments",
    Courses: "Courses",
    Tests: "Tests",
    PracticeFiles: "PracticeFiles",
    ChatsandMore:"ChatsandMore"
};

export const returnFeatureData = (featureType) => {
    switch (featureType) {
        case featureTypeEnum.LiveClasses:
            return [
                { image: liveClassImg, topText: "Attend Live Classes", bottomText: "The classroom experience, from the comfort of your home!", bgColor: "#FFE0E4" },
                { image: classInteractionImage, topText: "Live Class Interaction", bottomText: "Raise your hand to ask doubts and get the interaction started!", bgColor: "#FFE0E4" },
                { image: recordingsImg, topText: "Access to Recordings", bottomText: "Get recordings of live classes for your reference", bgColor: "#FFE0E4" }
            ]
        case featureTypeEnum.Assignments:
            return [
                { image: assignment1, topText: "Easy assignment submissions", bottomText: "Submit and manage all your assignments from one place!", bgColor: "#E5F8BD" },
                { image: assignment2, topText: "Feedback on submissions", bottomText: "Get feedback! Hear what your teacher has to say.", bgColor: "#E5F8BD" },
                { image: assignment3, topText: "Reminders!", bottomText: "Get feedback! Hear what your teacher has to say.", bgColor: "#E5F8BD" }
            ]
        case featureTypeEnum.Courses:
            return [
                { image: courses1, topText: "Learn on the go", bottomText: "Learn at your own pace from the comfort of your place!", bgColor: "#D6EFFA" },
                { image: courses2, topText: "Check all details before purchase", bottomText: "Review course contents and structure before buying any course", bgColor: "#D6EFFA" },
            ]
        case featureTypeEnum.Tests:
            return [
                { image: test1, topText: "Practice is best way to learn", bottomText: "Attempt Online or Offiline tests alloted by the teacher or institute", bgColor: "#F4E7FF" },
                { image: test2, topText: "Test Results", bottomText: "Get your performance report card and go ahead to improve", bgColor: "#F4E7FF" },
                { image: test3, topText: "Peer Challenges", bottomText: "Challenge your friends to keep up fair competitive spirit", bgColor: "#F4E7FF" }
            ]
        case featureTypeEnum.PracticeFiles:
            return [
                { image: practicalfile1, topText: "Study Material", bottomText: "Have access to all shared Study Material files", bgColor: "#D6F8F4" },
                { image: practicalfile2, topText: "Learn from all file types", bottomText: "Go through videos, notes, presentations, documents and much more", bgColor: "#D6F8F4" },
            ]
        case featureTypeEnum.ChatsandMore:
            return [
                { image: candm1, topText: "Communicate with teacher", bottomText: "Chat with you teacher for solving all your doubts and updates", bgColor: "#FFF4D1" },
                { image: candm1, topText: "Group Chats", bottomText: "Chat with friends or fellow students to keep up your learning curve", bgColor: "#FFF4D1" },
            ]
        default: 
            return [
                { image: liveClassImg, topText: "Attend Live Classes", bottomText: "The classroom experience, from the comfort of your home!", bgColor: "#FFE0E4" },
                { image: classInteractionImage, topText: "Live Class Interaction", bottomText: "Raise your hand to ask doubts and get the interaction started!", bgColor: "#FFE0E4" },
                { image: recordingsImg, topText: "Access to Recordings", bottomText: "Get recordings of live classes for your reference", bgColor: "#FFE0E4" }
            ]
    }
}