import React from 'react';
import '../css/guestLogin.css';

const Card = (props) => {
	let { image, topText, bottomText, bgColor } = props

	return (
		<div className="card-container">
			<div className="card-container-top" style={{ background: bgColor }}>
				<div className="card-container-top-Text">{topText}</div>
				<div className="card-container-top-Image">
					<img src={image} alt="" />
				</div>
			</div>
			<div className="card-container-bottom">{bottomText}</div>
		</div>
	);
};

export default Card;
