import React, { Component } from 'react';
import '../css/guestLogin.css';

import Card from './Card';
class GuestLogin extends Component {

	signUpToGetAccess = () => {	
		console.log('pppppp', this.props.isAppUpdated)

		if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
			if (!this.props.isAppUpdated) {
				window.mobile.onDeeplinkExecuted(
					`UTIL_BOTTOMSHEET,SIGNUP,You are currently viewing as a guest. Log in to get full access of the app`
				  );
			}else {
				window.mobile.onDeeplinkExecuted(
					`UTIL_LOGIN_REGISTRATION,level_0,You are currently viewing as a guest. Log in to get full access of the app`
				  );
			}
			


		}

	  };

	render() {
		return (
			<div>
				<div className="guest-cards">
					{this.props.featureType.map((featureTypeItem, index) => {
						return <Card key={index} image={featureTypeItem.image} topText={featureTypeItem.topText} bottomText={featureTypeItem.bottomText} bgColor={featureTypeItem.bgColor} />
					})}
				</div>

				<div className="guest-footer">
					<button className="guest-button" onClick={this.signUpToGetAccess}>Sign up to get full access</button>
				</div>
			</div>
		);
	}
}

export default GuestLogin;
